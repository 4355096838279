
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import moment from "moment";
import { ElNotification } from "element-plus";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "LogConnection",
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Лог бүртгэл");
    });
  },
  data() {
    return {
      tableloading: false,
      page: 1,
      pageSize: 10,
      loginfoSearch: "",
      selectedDate: "",
      isEmpty: false,
      infoLogData: [],
      shortcuts: [
        {
          text: "Өчигдөр",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            return [start, end];
          })()
        },
        {
          text: "5 өдөр",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 5);
            return [start, end];
          })()
        },
        {
          text: "7 өдөр",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          })()
        },
        {
          text: "14 өдөр",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 14);
            return [start, end];
          })()
        },
        {
          text: "1 сар",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          })()
        }
      ]
    };
  },
  mounted() {
    this.getInfoLog();
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val;
    },
    handleChange(value) {
      this.pageSize = value;
    },
    datepickerhandlechange() {
      this.isEmpty = false;
      if (!this.selectedDate || this.selectedDate.length === 0) {
        this.getInfoLog();
      }
    },
    async searchDate() {
      if (!this.selectedDate || this.selectedDate.length === 0) {
        this.isEmpty = true;
        return;
      }
      this.tableloading = true;
      await ApiService.post(`connectionloglist`, {
        params: {
          dateStart: moment(String(this.selectedDate[0])).format("YYYY/MM/DD"),
          dateEnd: moment(String(this.selectedDate[1])).format("YYYY/MM/DD")
        }
      })
        .then(resp => {
          const results = resp.data;
          if (results.code === 0) {
            ElNotification({
              title: "Алдаа",
              message: results.error,
              type: "error",
              iconClass: "el-text-error"
            });
          } else if (results.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: results.warning,
              type: "warning",
              iconClass: "el-text-warning"
            });
          } else {
            this.infoLogData = results.response.map(log => ({
              rownum: log.rownum,
              accessdate: log.accessdate,
              ipaddress: log.ipaddress,
              status: log.status,
              username: log.username,
              searchfield: log.searchfield
            }));
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Өгөгдөл уншихад алдаа гарлаа.",
            type: "error",
            iconClass: "el-text-error"
          });
        })
        .finally(() => {
          this.tableloading = false;
        });
    },
    async getInfoLog() {
      this.tableloading = true;
      await ApiService.get(`connectionloglist`)
        .then(resp => {
          const results = resp.data;
          if (results.code === 0) {
            ElNotification({
              title: "Алдаа",
              message: results.error,
              type: "error",
              iconClass: "el-text-error"
            });
          } else if (results.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: results.warning,
              type: "warning",
              iconClass: "el-text-warning"
            });
          } else {
            this.infoLogData = results.response.map(log => ({
              rownum: log.rownum,
              accessdate: log.accessdate,
              ipaddress: log.ipaddress,
              status: log.status,
              username: log.username,
              searchfield: log.searchfield
            }));
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Өгөгдөл уншихад алдаа гарлаа.",
            type: "error",
            iconClass: "el-text-error"
          });
        })
        .finally(() => {
          this.tableloading = false;
        });
    }
  }
});
